import React from "react";
import {
  Layout,
  Input,
  Select,
  Row,
  Col,
  DatePicker,
  Space,
  Button,
  Tooltip,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";

import "../main.css";
import DataRepositoryProxy from "../proxies/dataRepositoryProxy";
import DynamicDataRepositoryDashProxy from "../proxies/dashProxy";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";
import PricingHubProxy from "../proxies/pricinghubProxy";

const { Content } = Layout;
const { Option } = Select;

class InstrumentSearchAndFilter extends React.Component {
  static contextType = AppContext;
  csvLinkExport = React.createRef();
  csvLinkFullExport = React.createRef();

  constructor(props) {
    super();
    this.urlSearch = new URLSearchParams(props.location.search);
    this.dataRepositoryProxy = new DataRepositoryProxy();
    this.dynamicDataRepositoryDashProxy = new DynamicDataRepositoryDashProxy(
      {}
    );
    this.pricingHubProxy = new PricingHubProxy();
    this.connectorsCoordinatorProxy = new ConnectorsCoordinatorProxy();

    this.currentSearchAndFilter = {
      search: undefined,
      operationValue: "contains",
      currentPage: 1,
      providerValue: undefined,
      priceStatusValues: [],
      tagValue: [],
      namesFromSearch: [],
      dateFilter: { date: "", isBefore: true },
    };
    this.state = {
      dateBeforeOrAfter: "before",
      currentSearchAndFilter: this.currentSearchAndFilter,
      priceStatusOptions: [],
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    const state = this.context.state;

    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      namesFromSearch: state.instrumentSearchName || [],
      tagValue: state.instrumentSearchTags || [],
    };
    let convertedProviders = await this.populateProviders();
    const foundNames = this.extractParametersFromUrl();
    this.extractProviderOrTagFromSearchValue(foundNames, convertedProviders);

    let { convertedTags, selectedTag } =
      await this.getTagsFromPricingHubAndSelectFirst();

    let priceStatusOptions = await this.getPriceStatusOptions();

    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      search: this.populateSearch(),
      operationValue:
        state.instrumentSearchOperation ||
        this.currentSearchAndFilter.operationValue,
      providerValue: undefined,
      priceStatusValues:
        state.instrumentSearchPriceStatusValues ||
        this.currentSearchAndFilter.priceStatusValues,
      tagValue: selectedTag?.value || [],
    };
    this.setState({
      tags: [...convertedTags],
      providers: convertedProviders,
      priceStatusOptions: [...priceStatusOptions],
    });
    await this.onNewChange();
  };

  extractParametersFromUrl() {
    const { id } = this.props.params;

    if (id) {
      this.currentSearchAndFilter.namesFromSearch = [
        {
          name: id.toLowerCase(),
          provider: undefined,
        },
      ];
    } else {
      let foundNames = this.urlSearch.getAll("name");
      let operation = this.urlSearch.get("operation");
      let provider = this.urlSearch.get("provider");
      let tag = this.urlSearch.get("tag");

      if (foundNames.length > 0 || operation || provider || tag) {
        this.currentSearchAndFilter = {
          ...this.currentSearchAndFilter,
          namesFromSearch: [],
        };
      }

      if (operation) {
        this.currentSearchAndFilter.operationValue = operation;
      }

      if (tag) {
        this.currentSearchAndFilter.tagValue.push(tag);
      }

      return foundNames;
    }
  }

  extractProviderOrTagFromSearchValue(search, providers) {
    // example search: ig_other.alumin #Indicies
    // Want ig.other to be set to this.currentSearchAndFilter.namesFromSearch.provider
    // alumin : this.currentSearchAndFilter.namesFromSearch.name
    // indicies : this.currentSearchAndFilter.tagValue
    if (search?.length > 0) {
      search.forEach((token) => {
        token = token.replace('"', "");

        if (token.includes("#")) {
          const tag = token.replace("#", "").toLowerCase().trim();
          this.currentSearchAndFilter.tagValue.push(tag);
        } else if (token) {
          let segments = token.toLowerCase().trim().split(".");
          const lowerCaseToken = segments[0].toLowerCase();
          const foundProvider = providers.find(
            (x) => x.lowerCase === lowerCaseToken
          );

          if (foundProvider) {
            segments.shift();
          }

          const name = segments.join(".");
          this.currentSearchAndFilter.namesFromSearch.push({
            name,
            provider: foundProvider?.value,
          });
        }
      });
    }
  }

  populateProviders = async () => {
    const providers = await this.pricingHubProxy.providers();
    const convertedProviders = providers.map((x) => ({
      value: x.name,
      key: x.id,
      lowerCase: x.name.toLowerCase(),
    }));

    // Sort the converted providers by value
    convertedProviders.sort((x, y) => x.value.localeCompare(y.value));

    this.setState({ providers: convertedProviders });

    return convertedProviders;
  };

  populateSearch = () => {
    let mappedTags = [];
    if (this.currentSearchAndFilter.namesFromSearch) {
      mappedTags = [
        ...this.currentSearchAndFilter.namesFromSearch.map((x) => {
          var item = x.provider
            ? x.provider + "." + x.name
            : x.name.includes(" ")
            ? '"' + x.name + '"'
            : x.name;
          return item;
        }),
      ];
    }
    if (this.currentSearchAndFilter.tagValue?.length > 0)
      mappedTags = mappedTags.concat(
        this.currentSearchAndFilter.tagValue.map((x) => {
          if (x.includes(" ")) return '"#' + x + '"';
          return "#" + x;
        })
      );
    let result = mappedTags.join(" ");
    return result;
  };

  onNewChange = async () => {
    this.setState({ currentSearchAndFilter: this.currentSearchAndFilter });
    await this.props.onNewChange(this.currentSearchAndFilter);
  };

  onOperationChange = async (value) => {
    if (!value) value = "contains";
    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      operationValue: value,
    };
    await this.onNewChange();
  };

  onSearchChange = async ({ target: { value: search } }) => {
    this.page = 1;

    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      namesFromSearch: [],
      search,
      currentPage: 1,
    };

    this.extractProviderOrTagFromSearchValue(
      search.toLowerCase().match(/(?:[^\s"]+|"[^"]*")+/g),
      this.state.providers
    );

    await this.onNewChange();
  };

  onProviderChange = async (value) => {
    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      providerValue: value,
    };

    await this.onNewChange();
  };

  onTagChange = async (value) => {
    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      tagValue: value ? [value.trim()] : [],
    };

    await this.onNewChange();
  };

  onPriceStatusChange = async (value) => {
    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      priceStatusValues: value || [],
    };

    await this.onNewChange();
  };

  onDateChange = async (value, dateString) => {
    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      dateFilter: {
        ...this.currentSearchAndFilter.dateFilter,
        date: dateString,
      },
    };

    await this.onNewChange();
  };

  toggleDateBeforeOrAfter = async (_) => {
    const isBefore = this.state.dateBeforeOrAfter !== "before";

    this.setState({
      dateBeforeOrAfter: isBefore ? "before" : "after",
    });

    this.currentSearchAndFilter = {
      ...this.currentSearchAndFilter,
      dateFilter: {
        ...this.currentSearchAndFilter.dateFilter,
        isBefore: isBefore,
      },
    };

    await this.onNewChange();
  };

  async getTagsFromPricingHubAndSelectFirst() {
    let tags = await this.pricingHubProxy.getTagOptions(true);
    let convertedTags = tags.map((x) => ({ value: x.name, key: x.id })).sort();

    const { tags: stateTags } = this.currentSearchAndFilter.tagValue;
    let selectedTag = convertedTags.find(
      (x) => x.value === (stateTags?.length > 0 ? stateTags[0] : undefined)
    );

    return { convertedTags, selectedTag };
  }

  async getPriceStatusOptions() {
    let priceStatusOptions = await this.pricingHubProxy.getPriceStatusOptions();
    let convertedStatus = [];
    priceStatusOptions.forEach((x) => {
      convertedStatus.push({ value: x.item2, key: x.item1 });
    });
    return convertedStatus;
  }

  searchOrFilterPopulated() {
    const currentSearchAndFilter = this.state.currentSearchAndFilter;
    return (
      currentSearchAndFilter.namesFromSearch ||
      currentSearchAndFilter.priceStatusValues ||
      currentSearchAndFilter.providerValue ||
      currentSearchAndFilter.tagValue ||
      currentSearchAndFilter.search
    );
  }

  render() {
    const { currentSearchAndFilter, providers, tags, priceStatusOptions } =
      this.state;
    return (
      <Layout className="layout">
        <Content style={{ padding: "0 5px" }}>
          <Row gutter={[16, 16]}>
            <Col flex="4">
              <Input
                allowClear
                prefix={<SearchOutlined />}
                placeholder="#tag (Market Monitor super groups & groups, no spaces), instrument, description"
                onChange={this.onSearchChange}
                value={currentSearchAndFilter.search}
              />
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col flex="0.5">
              <Select
                showSearch
                allowClear
                placeholder="search operations"
                onChange={this.onOperationChange}
                value={currentSearchAndFilter.operationValue}
                style={{ width: "100%" }}
              >
                <Option value="contains">Contains</Option>
                <Option value="equals">Equals</Option>
              </Select>
            </Col>
            <Col flex="1">
              <Select
                showSearch
                allowClear
                placeholder="Provider"
                onChange={this.onProviderChange}
                options={providers}
                value={currentSearchAndFilter.providerValue}
                style={{ width: "100%" }}
              />
            </Col>
            <Col flex="1">
              <Select
                showSearch
                allowClear
                placeholder="Tag"
                onChange={this.onTagChange}
                options={tags}
                value={currentSearchAndFilter.tagValue}
                style={{ width: "100%" }}
              />
            </Col>
            <Col flex="1.0">
              <Select
                mode="multiple"
                showSearch
                allowClear
                placeholder="Price Status"
                onChange={this.onPriceStatusChange}
                options={priceStatusOptions}
                value={currentSearchAndFilter.priceStatus}
                style={{ width: "100%" }}
              />
            </Col>
            <Col flex="1.5">
              <Tooltip
                title={
                  this.state.currentSearchAndFilter.namesFromSearch.length >
                    0 ||
                  this.state.currentSearchAndFilter.priceStatusValues.length >
                    0 ||
                  this.state.currentSearchAndFilter.providerValue ||
                  this.state.currentSearchAndFilter.tagValue.length > 0 ||
                  this.state.currentSearchAndFilter.search
                    ? ""
                    : "Selecting a date without other filters may result in slow performance. Consider narrowing down your search criteria for a more efficient query."
                }
              >
                <DatePicker
                  renderExtraFooter={() => (
                    <Space>
                      {"Last seen "}
                      <Button
                        type="primary"
                        size="small"
                        onClick={this.toggleDateBeforeOrAfter}
                      >
                        {this.state.dateBeforeOrAfter}
                      </Button>
                      {" this date. "}
                    </Space>
                  )}
                  onChange={this.onDateChange}
                  style={{ width: "100%" }}
                />
              </Tooltip>
            </Col>
          </Row>
        </Content>
      </Layout>
    );
  }
}

InstrumentSearchAndFilter.contextType = AppContext;
export default Helpers.withParams(InstrumentSearchAndFilter);
